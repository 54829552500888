import React from "react"

import {
  Box,
  Flex,
  Text,
  Link,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react"

import { Link as GatsbyLink } from "gatsby"

import { ALL_STAR_PAGES_URL } from "../../../constants/constants"

const styles = {
  root: {
    width: "100%",
    px: { base: "16px", md: "24px", lg: "24px" },
    bg: "#F4F7FF",
    justifyContent: "center",
  },
  content: {
    my: { base: "24px", md: "24px", lg: "60px" },
    width: "1080px",
  },
  "inner-box": {
    p: "36px",
    width: "1080px",
    bg: "#FFFFFF",
  },
  heading: {
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "32px",
  },
  note: {
    fontSize: "16px",
    fontStyle: "italic",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#6E7E99",
  },
  text: {
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#2D3748",
  },
  question: {
    fontSize: { base: "16px", md: "16px", lg: "18px" },
    fontWeight: 700,
    lineHeight: "21px",
    py: "24px",
    px: { base: "16px", md: "16px", lg: "32px" },
  },
  answer: {
    pb: "16px",
    px: { base: "16px", md: "16px", lg: "32px" },
    pt: "0",
  },
  link: {
    fontSize: "16px",
    color: "#3399FF",
    fontWeight: 400,
    lineHeight: "24px",
  },
}

const FAQ = [
  {
    question: "What are the Healthcare Research All-Star Awards?",
    answer: `The Healthcare Research All-Star Awards honor the outstanding surgeons and
      hospitals whose contributions to healthcare research significantly enhance 
      the quality of patient care.  The study examined the research of more than 
      4,000 hospitals and 90,000 surgeons and includes only the top five percent 
      based on the quantity and quality of their published research.`,
  },
  {
    question:
      "What criteria were used to determine inclusion as a Healthcare Research All-Star?",
    answer: (
      <Text>
        Our methodology for producing the lists of Healthcare Research All-Stars
        is explained in detail{" "}
        <Link
          sx={styles.link}
          as={GatsbyLink}
          to={"/" + ALL_STAR_PAGES_URL.METHODOLOGY + "/"}
        >
          here
        </Link>{" "}
        .
      </Text>
    ),
  },
  {
    question:
      "Why did Avant-garde Health publish the Healthcare Research All-Stars?",
    answer: `Avant-garde Health initiated the publication of the Healthcare Research 
      All-Stars Lists to honor the outstanding surgeons and hospitals whose contributions 
      to healthcare research significantly enhance the quality of patient care. 
      By acknowledging the pivotal role that rigorous research plays in advancing medical 
      treatments and outcomes, we aim to shine a spotlight on these individuals. 
      Leveraging our extensive expertise in analytics and our proven track record in research 
      dissemination, we are uniquely positioned to undertake the comprehensive evaluation necessary 
      to compile this distinguished roster of Healthcare Research All-Stars.`,
  },
  {
    question: "Why are only surgeons honored as Healthcare Research All-Stars?",
    answer: `The focus on surgical specialties in the Research All-Stars lists is 
      a direct reflection of Avant-garde Health's specialization in analytics for 
      perioperative care. Our foundational expertise is deeply rooted in collaborating 
      with surgeons and perioperative leaders, in addition to supporting surgeons in their 
      research endeavors. Consequently, the inaugural edition of the Healthcare Research 
      All-Stars list is exclusively dedicated to surgeons. However, it is important to 
      note that we are considering the inclusion of non-surgical specialties in future 
      iterations of the All-Stars lists, with a goal of broadening the scope of 
      recognition to encompass a wider array of medical disciplines.`,
  },
  {
    question: "Are surgeons compensated as Healthcare Research All-Stars?",
    answer: (
      <Text>
        Inclusion as a Healthcare Research All-Star, for both surgeons and
        hospitals, is strictly a merit-based honor. No compensation has been
        offered to or requested from honorees. Our methodology for producing the
        lists of Healthcare Research All-Stars is explained in detail{" "}
        <Link
          sx={styles.link}
          as={GatsbyLink}
          to={"/" + ALL_STAR_PAGES_URL.METHODOLOGY + "/"}
        >
          here
        </Link>
        .
      </Text>
    ),
  },
  {
    question:
      "In addition to physicians, why are hospitals also honored as Healthcare Research All-Stars?",
    answer: (
      <Text>
        Hospitals are recognized as Research All-Stars alongside surgeons
        because of the significant investments they make in research, which
        often require considerable time and financial resources. These
        institutions play a crucial role in supporting medical research by
        offering essential resources and flexible scheduling arrangements,
        thereby enabling physicians to pursue their research endeavors. Without
        such institutional support, conducting research studies would not be
        feasible for many individual physicians. The Research All-Stars
        initiative, therefore, extends its accolades to not only the individual
        physicians but also to the hospitals that provide this backing.
        Hospitals with a strong orientation toward research are typically at the
        forefront of adopting innovative clinical practices, which contributes
        to the enhancement of care quality. The criteria used to select
        hospitals for this honor are detailed within our{" "}
        <Link
          sx={styles.link}
          as={GatsbyLink}
          to={"/" + ALL_STAR_PAGES_URL.METHODOLOGY + "/"}
        >
          research methodology
        </Link>
        .
      </Text>
    ),
  },
  {
    question:
      "How often will the Healthcare Research All-Stars Awards be published?",
    answer: "The awards will be published annually.",
  },
  {
    question: "Who is Avant-garde Health?",
    answer: (
      <>
        <Text>
          <Link sx={styles.link} isExternal href="/">
            Avant-garde Health
          </Link>{" "}
          provides health systems, surgery centers, and physicians with
          comprehensive insight into their surgical care through our software
          and empowers them to improve their finances and deliver the best care
          possible to their patients.
        </Text>
        <Text mt="12px">
          We also apply our expertise in healthcare analytics to assist surgeons
          with their research projects. Our{" "}
          <Link sx={styles.link} isExternal href="/vbhc-research-group">
            Value-Based Healthcare Research Group
          </Link>{" "}
          is supported by a dedicated team at Avant-garde and together they
          publish 8-10 manuscripts per year.
        </Text>
        <Text mt="12px">
          We are a mission-driven organization that was born out of the
          value-based health care research at Harvard Business School led by
          Michael Porter and Bob Kaplan. We are committed to advancing knowledge
          and have published articles across Harvard Business Review, NEJM, and
          JAMA, as well as in many other journals.
        </Text>
      </>
    ),
  },
]

const SectionBody = () => {
  return (
    <Flex sx={styles.root}>
      <Box sx={styles.content}>
        <Text sx={styles.heading}>Frequently Asked Questions</Text>

        <Accordion
          defaultIndex={[0]}
          allowMultiple
          w="100%"
          borderColor="#FFFFFF"
          mt="20px"
        >
          {FAQ.map(faq => {
            return (
              <AccordionItem
                bg="#FFFFFF"
                mt="8px"
                borderRadius="4px"
                key={faq.question}
              >
                <h2>
                  <AccordionButton sx={styles.question}>
                    <Box as="span" flex="1" textAlign="left">
                      {faq.question}
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel sx={styles.answer}>
                  <Box sx={styles.text}>{faq.answer}</Box>
                </AccordionPanel>
              </AccordionItem>
            )
          })}
        </Accordion>
      </Box>
    </Flex>
  )
}

export default SectionBody
